<template>
  <div class="outer-page">
    <article-info></article-info>
  </div>
</template>

<script>
import articleInfo from "@components/articleInfo";
export default {
  name: "",
  components: {
    articleInfo,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="less" scoped>
</style>